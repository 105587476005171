import { Wrapper } from 'lib';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoute } from 'ui/navigation';
import * as pages from 'ui/pages';

export const RoutesObj = () => (
  <Wrapper>
    <Routes>
      <Route path='/accedi' element={<pages.LoginPage />} />

      <Route path='/richieste-giocatori' element={<PrivateRoute />}>
        <Route path='/richieste-giocatori' element={<pages.PlayersRequestsPage />} />
      </Route>

      <Route path='/merge-giocatori' element={<PrivateRoute />}>
        <Route path='/merge-giocatori' element={<pages.PlayersMerge />} />
      </Route>

      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<pages.DashboardPage />} />
      </Route>

      <Route path='/utenti' element={<PrivateRoute />}>
        <Route path='/utenti' element={<pages.UsersPage />} />
      </Route>

      <Route path='/utenti/gestori-circoli' element={<PrivateRoute />}>
        <Route path='/utenti/gestori-circoli' element={<pages.ClubManagersPage />} />
      </Route>

      <Route path='/database' element={<PrivateRoute />}>
        <Route path='/database' element={<pages.DatabasePage />} />
      </Route>

      <Route path='/notifiche' element={<PrivateRoute />}>
        <Route path='/notifiche' element={<pages.NotificationsPage />} />
      </Route>

      <Route path='/partite' element={<PrivateRoute />}>
        <Route path='/partite' element={<pages.MatchesPage />} />
      </Route>

      <Route path='/aggiornamento-dati' element={<PrivateRoute />}>
        <Route path='/aggiornamento-dati' element={<pages.UpdateTasksPage />} />
      </Route>

      <Route path='/email-conferma-account' element={<PrivateRoute />}>
        <Route path='/email-conferma-account' element={<pages.AccountConfirmationEmailPage />} />
      </Route>

      <Route path='/cardinalita' element={<PrivateRoute />}>
        <Route path='/cardinalita' element={<pages.RankingsPage />} />
      </Route>

      <Route path='/marketplace' element={<PrivateRoute />}>
        <Route path='/marketplace' element={<pages.MarketplacePage />} />
        <Route path='/marketplace/:categoryId' element={<pages.MarketplacePage />} />
      </Route>

      <Route path='/segnalazioni' element={<PrivateRoute />}>
        <Route path='/segnalazioni' element={<pages.ReportsPage />} />
        <Route path='/segnalazioni/:id/risolvi' element={<pages.EditReportPage />} />
      </Route>

      <Route path='/adsense' element={<PrivateRoute />}>
        <Route path='/adsense' element={<pages.AdsensePage />} />
        <Route path='/adsense/:id/modifica' element={<pages.EditAdsensePage />} />
      </Route>

      <Route path='/custom-banner' element={<PrivateRoute />}>
        <Route path='/custom-banner' element={<pages.CustomBannersPage />} />
        <Route path='/custom-banner/:id/modifica' element={<pages.EditCustomBannerPage />} />
      </Route>

      <Route path='/gestione-banner' element={<PrivateRoute />}>
        <Route path='/gestione-banner' element={<pages.BannersManagementPage />} />
        <Route path='/gestione-banner/:id/modifica' element={<pages.EditBannerPage />} />
      </Route>

      <Route path='/codici-membership' element={<PrivateRoute />}>
        <Route path='/codici-membership' element={<pages.MembershipCodesPage />} />
        <Route path='/codici-membership/:id/modifica' element={<pages.EditMembershipCodesPage />} />
      </Route>
    </Routes>
  </Wrapper>
);
