import classNames from 'classnames';

import * as theme from '../theme';

const baseStyle = {
  width: '100%',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  borderRadius: theme.sizes.borderRadius,
  borderCollapse: 'collapse',
};

const headStyle = {
  borderBottomWidth: '2px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.colors.lightGrey,
};

export const Table = ({ head, foot, rows, rowRenderer, className, style }) => (
  <table className={classNames('table', className)} style={{ ...baseStyle, ...style }}>
    {head && <thead style={headStyle}>{head}</thead>}
    <tbody>{rows && rowRenderer && rows.map(rowRenderer)}</tbody>
    {foot && <tfoot>{foot}</tfoot>}
  </table>
);
