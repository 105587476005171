import API from 'api';
import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

export const getAllCustomBanners = () => (dispatch) => {
  API.customBanners.all(
    (response) => {
      dispatch(allBannersFetched(response));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    },
  );
};

export const save = (data) => (dispatch) => {
  API.customBanners.create(
    { custom_banner: data },
    (response) => {
      UIkit.notification('Banner inserito con successo', {
        status: 'success',
      });
      dispatch(bannerCreated({ ...response.custom_banner }));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    },
  );
};

export const fetchBanners = (page) => (dispatch) => {
  API.customBanners.fetch(
    page,
    (response) => {
      dispatch(bannersFetched(response));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    },
  );
};

export const deleteBanner = (id) => (dispatch) => {
  API.customBanners.deleteBanner(
    id,
    (response) => {
      UIkit.notification('Banner eliminato con successo', {
        status: 'success',
      });
      dispatch(bannersDeleted(response.custom_banner));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    },
  );
};

export const fetchBanner = (id) => (dispatch) => {
  API.customBanners.show(
    id,
    (response) => {
      dispatch(bannerFetched(response));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    },
  );
};

export const updateBanner = (id, data) => (dispatch) => {
  API.customBanners.update(
    id,
    { custom_banner: data },
    () => {
      UIkit.notification('Banner aggiornato con successo', {
        status: 'success',
      });
      window.location.href = '/custom-banner';
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    },
  );
};

const allBannersFetched = (response) => ({
  type: actionTypes.CUSTOM_BANNERS_ALL_FETCHED,
  payload: response,
});

const bannersFetched = (response) => ({
  type: actionTypes.CUSTOM_BANNERS_FETCHED,
  payload: response,
});

const bannerCreated = (banner) => ({
  type: actionTypes.CUSTOM_BANNER_CREATED,
  payload: banner,
});

const bannersDeleted = (banner) => ({
  type: actionTypes.CUSTOM_BANNER_DELETED,
  payload: banner,
});

const bannerFetched = (banner) => ({
  type: actionTypes.CUSTOM_BANNER_FETCHED,
  payload: banner,
});

const bannerActionFailed = (error) => ({
  type: actionTypes.CUSTOM_BANNER_ACTION_FAILED,
  payload: error,
});
