import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PaginationBar } from 'ui/components';

import { Wrapper, Loader, Header, WorkspaceContent, CheckBox, Table, Tr, Th, Td, Icon, TextInput } from 'lib';
import { Page } from 'ui/elements';
import { UsersMenu } from 'ui/navigation';

import {
  fetchUsers,
  searchUsers,
  setHiddenResults,
  setPremiumUser,
  ban,
  unban,
  setPremiumExpirationDate,
} from 'store/users/actions';

const UsersPage = ({
  loading,
  error,
  users,
  page,
  totalPages,
  fetch,
  search,
  setPremiumExpirationDate,
  ban,
  unban,
  setHiddenResults,
  setPremiumUser,
}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    fetch();
  }, []);

  const renderError = () => <p>errore</p>;

  const onChangeFn = (e) => {
    setValue(e.target.value);
    search(e.target.value);
  };

  const getDate = (user) => {
    const date = new Date(user.premium_expiration);
    let day = date.getDate(),
      month = date.getMonth() + 1;
    const year = date.getFullYear();
    month = (month < 10 ? '0' : '') + month;
    day = (day < 10 ? '0' : '') + day;

    const today = `${year}-${month}-${day}`;
    return today;
  };

  let content = <Loader />;
  if (!loading) {
    if (error) {
      content = renderError();
    } else if (users) {
      content = (
        <>
          <input className='uk-margin-bottom' type='text' value={value} onChange={onChangeFn} />
          <Table
            className='users-list'
            head={
              <Tr>
                <Th className='uk-table-shrink uk-text-center'>ID</Th>
                <Th className='uk-table-expand uk-text-left'>Nome</Th>
                <Th className='uk-table-expand uk-text-left'>Cognome</Th>
                <Th className='uk-table-expand uk-text-left'>Giocatore</Th>
                <Th className='uk-table-expand uk-text-left'>E-mail</Th>
                <Th className='uk-table-shrink uk-text-center uk-text-nowrap'>Scadenza Premium</Th>
                <Th className='uk-table-shrink uk-text-center'>Bannato?</Th>
                <Th className='uk-table-shrink uk-text-center'>Nascosto?</Th>
                <Th className='uk-table-shrink uk-text-center'>Premium?</Th>
              </Tr>
            }
            rows={users}
            rowRenderer={(user) => (
              <Tr key={`user-${user.id}`}>
                <Td className='uk-table-shrink uk-text-center'>
                  <a href={`https://www.tennistalker.it/utente/${user.id}`} target='_blank' rel='noopener noreferrer'>
                    {user.id}
                  </a>
                </Td>
                <Td>{user.first_name}</Td>
                <Td>{user.last_name}</Td>
                <Td>
                  <a
                    href={`https://www.tennistalker.it/giocatore/${user.fit_player_profile?.id}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {user.fit_player_profile?.full_name}
                  </a>
                </Td>
                <Td>{user.email}</Td>
                <Td className='uk-table-shrink uk-text-center'>
                  {user.loaders.premiumExpirationDate ? (
                    <Loader small />
                  ) : (
                    <TextInput
                      type='date'
                      value={getDate(user)}
                      step='1'
                      onChange={(event) => setPremiumExpirationDate(user.id, event.target.valueAsNumber)}
                      className='uk-text-center'
                    />
                  )}
                </Td>
                <Td className='uk-table-shrink uk-text-center'>
                  {user.loaders.isBanned ? (
                    <Loader small />
                  ) : (
                    <Wrapper>
                      <CheckBox
                        checked={user.is_banned}
                        onChange={(checked) => {
                          if (checked) {
                            const notes = prompt('Motivazione del ban');
                            ban(user.id, notes);
                          } else {
                            unban(user.id);
                          }
                        }}
                      />

                      {user.is_banned && (
                        <Icon
                          name='info-circle'
                          className='uk-margin-small-left'
                          title={user.ban_notes}
                          data-uk-tooltip
                        />
                      )}
                    </Wrapper>
                  )}
                </Td>
                <Td className='uk-table-shrink uk-text-center'>
                  {user.loaders.hiddenResults ? (
                    <Loader small />
                  ) : (
                    <CheckBox checked={user.hidden_results} onChange={() => setHiddenResults(user.id)} />
                  )}
                </Td>
                <Td className='uk-table-shrink uk-text-center'>
                  {user.loaders.premiumUser ? (
                    <Loader small />
                  ) : (
                    <CheckBox checked={user.premium} onChange={() => setPremiumUser(user.id)} />
                  )}
                </Td>
              </Tr>
            )}
          />
        </>
      );
    }
  }

  return (
    <Page title='Utenti' className='users' secondaryMenu={<UsersMenu />}>
      <Header title='Utenti' />

      <WorkspaceContent>{content}</WorkspaceContent>
      <PaginationBar
        currentPage={page}
        totalPages={totalPages}
        onPreviousPageClick={() => (value ? search(value, page - 1) : fetch(page - 1))}
        onNextPageClick={() => (value ? search(value, page + 1) : fetch(page + 1))}
        onPageClick={(page) => (value ? search(value, page) : fetch(page))}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({ ...state.users });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchUsers(page)),
  search: (q, page = 1) => dispatch(searchUsers(q, page)),
  setHiddenResults: (userId) => dispatch(setHiddenResults(userId)),
  setPremiumUser: (userId) => dispatch(setPremiumUser(userId)),
  ban: (userId, notes) => dispatch(ban(userId, notes)),
  unban: (userId) => dispatch(unban(userId)),
  setPremiumExpirationDate: (userId, date) => dispatch(setPremiumExpirationDate(userId, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
