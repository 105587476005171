import classNames from 'classnames';

import { PrimaryMenu } from 'ui/navigation';
import { Workspace } from 'lib';

const Page = ({ title, className, secondaryMenu, withoutMenus, children }) => (
  <>
    <title>{title} - Backoffice Tennistalker</title>
    <meta name='description' content={`Backoffice Tennistalker page - ${title}`} />
    <div className={classNames('page', className)}>
      <Workspace mainMenu={!withoutMenus && <PrimaryMenu />} secondaryMenu={secondaryMenu}>
        {children}
      </Workspace>
    </div>
  </>
);

export default Page;
