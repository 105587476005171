import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Loader, Header, WorkspaceContent, SecondaryButton, Table, Tr, Th, Td, Icon, TextInput } from 'lib';
import { Page } from 'ui/elements';

import { fetchMatches, updateMatch, deleteMatch, setMatchResult } from 'store/matches/actions';

class MatchesPage extends PureComponent {
  componentDidMount() {
    this.props.fetch();
  }

  renderError() {
    return <p>errore</p>;
  }

  render() {
    const { loading, error, matches } = this.props;

    let content = <Loader />;
    if (!loading) {
      if (error) {
        content = this.renderError();
      } else if (matches) {
        content = (
          <Table
            className='matches-list'
            head={
              <Tr>
                <Th className='uk-table-shrink uk-text-center'>ID</Th>
                <Th className='uk-table-expand uk-text-left'>Dettagli</Th>
                <Th className='uk-table-expand uk-text-left'>Vincitori</Th>
                <Th className='uk-table-expand uk-text-left'>Perdenti</Th>
                <Th className='uk-text-center'>Risultato</Th>
                <Th />
              </Tr>
            }
            rows={matches}
            rowRenderer={(match) => (
              <Tr key={`match-${match.id}`}>
                <Td className='uk-table-shrink uk-text-center'>{match.id}</Td>
                <Td>
                  <strong>Competizione:</strong> {match.competition.name}
                  <br />
                  <strong>Torneo:</strong> {match.tournament.fit_tournament_id}
                  <br />
                  <strong>Tabellone:</strong> {match.draw.fit_draw_id}
                </Td>
                <Td>
                  <ol>
                    <li>{match.first_winner.name}</li>
                    <li>{match.second_winner ? match.second_winner.name : '-'}</li>
                  </ol>
                </Td>
                <Td>
                  <ol>
                    <li>{match.first_loser.name}</li>
                    <li>{match.second_loser ? match.second_loser.name : '-'}</li>
                  </ol>
                </Td>
                <Td style={{ width: '200px' }} className='uk-text-center'>
                  <div className='uk-grid uk-grid-small'>
                    <div className='uk-width-expand'>
                      <TextInput
                        value={match.result}
                        onChange={(event) => this.props.setMatchResult(match.id, event.target.value)}
                        className='uk-text-center'
                      />
                    </div>
                    <div className='uk-width-auto'>
                      <SecondaryButton onClick={() => this.props.update(match)}>
                        <Icon name='floppy-o' />
                      </SecondaryButton>
                    </div>
                  </div>
                </Td>
                <Td className='uk-table-shrink uk-text-center'>
                  <SecondaryButton
                    onClick={() => {
                      if (window.confirm('Sei sicuro di voler eliminare questa partita?')) {
                        this.props.deleteMatch(match.id);
                      }
                    }}
                  >
                    <Icon name='trash' />
                  </SecondaryButton>
                </Td>
              </Tr>
            )}
          />
        );
      }
    }

    return (
      <Page title='Partite' className='matches'>
        <Header title='Partite' />

        <WorkspaceContent>{content}</WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.matches });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchMatches(page)),
  update: (match) => dispatch(updateMatch(match)),
  deleteMatch: (matchId, page) => dispatch(deleteMatch(matchId, page)),
  setMatchResult: (matchId, result) => dispatch(setMatchResult(matchId, result)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchesPage);
