import API from 'api';
import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

export const deleteTarget = (id) => (dispatch) => {
  API.reports.deleteTarget(
    id,
    () => {
      UIkit.notification('Segnalazione aggiornata con successo', {
        status: 'success',
      });
      window.location.href = '/segnalazioni';
      dispatch(fetchReports(1));
    },
    (error) => {
      dispatch(reportActionFailed(error));
    },
  );
};

export const fetchReports = (page) => (dispatch) => {
  API.reports.fetch(
    page,
    (response) => {
      dispatch(reportsFetched(response));
    },
    (error) => {
      dispatch(reportActionFailed(error));
    },
  );
};

export const fetchReport = (id) => (dispatch) => {
  API.reports.show(
    id,
    (response) => {
      dispatch(reportFetched(response));
    },
    (error) => {
      dispatch(reportActionFailed(error));
    },
  );
};

export const updateReport = (id, admin_notes) => (dispatch) => {
  API.reports.update(
    id,
    admin_notes,
    () => {
      UIkit.notification('Segnalazione aggiornata con successo', {
        status: 'success',
      });
      window.location.href = '/segnalazioni';
    },
    (error) => {
      dispatch(reportActionFailed(error));
    },
  );
};

const reportsFetched = (response) => ({
  type: actionTypes.REPORTS_FETCHED,
  payload: response,
});

const reportFetched = (report) => ({
  type: actionTypes.REPORT_FETCHED,
  payload: report,
});

const reportActionFailed = (error) => ({
  type: actionTypes.REPORT_ACTION_FAILED,
  payload: error,
});
