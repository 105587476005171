import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent, Button } from 'lib';
import { SearchablePlayer } from 'ui/components';
import { call } from 'api';
import UIkit from 'uikit';

class PlayersMerge extends PureComponent {
  state = {
    sourcePlayer: null,
    destinationPlayer: null,
  };

  submitMerge = () => {
    const { sourcePlayer, destinationPlayer } = this.state;
    if (sourcePlayer && destinationPlayer)
      call(
        `fit-player-profiles/merge/${sourcePlayer.id}/${destinationPlayer.id}`,
        { method: 'PUT' },
        () => {
          UIkit.notification('Merge effettuato con successo', {
            status: 'success',
          });
        },
        () => {
          UIkit.notification(
            'Si è verificato un errore durante il merge dei giocatori',
            {
              status: 'danger',
            },
          );
        },
      );
  };

  render() {
    const { sourcePlayer, destinationPlayer } = this.state;
    return (
      <Page title='Merge Giocatori' className='players-merge'>
        <Header title='Merge Giocatori' />
        <WorkspaceContent>
          <SearchablePlayer
            player={sourcePlayer}
            onChangePlayerHandler={(player) => this.setState({ sourcePlayer: player })
            }
            label='Giocatore sorgente'
          />
          <SearchablePlayer
            player={destinationPlayer}
            onChangePlayerHandler={(player) => this.setState({ destinationPlayer: player })
            }
            label='Giocatore di destinazione'
          />
          <Button
            onClick={this.submitMerge}
            disabled={
              !sourcePlayer ||
              !destinationPlayer ||
              sourcePlayer.id === destinationPlayer.id
            }
            className='merge-button'
          >
            Merge
          </Button>
        </WorkspaceContent>
      </Page>
    );
  }
}

export default connect(null, null)(PlayersMerge);
