import update from 'immutability-helper';

export const adsensesFetched = (state, action) => {
  const { total_pages, page, data } = action.payload;
  return update(state, {
    totalPages: { $set: total_pages },
    page: { $set: page },
    adsenses: {
      $set: data,
    },
  });
};

export const adsenseCreated = (state, action) => {
  const adsenses = [action.payload, ...state.adsenses];
  return update(state, { adsenses: { $set: adsenses } });
};

export const adsenseDeleted = (state, action) => {
  const adsenses = state.adsenses.filter(
    (adsense) => adsense.id !== action.payload.id,
  );
  return update(state, { adsenses: { $set: adsenses } });
};

export const adsenseFetched = (state, action) => update(state, { adsense: { $set: action.payload } });

export const allAdsensesFetched = (state, action) => update(state, { adsenses: { $set: action.payload } });
