import { PureComponent } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';

import { Page } from 'ui/elements';
import { call } from 'api';
import UIkit from 'uikit';
import { Button, Header, WorkspaceContent } from 'lib';

class AccountConfirmationEmailPage extends PureComponent {
  state = {
    email: '',
  };

  onSubmit = () => {
    const { email } = this.state;
    call(
      'users/account-confirmation-email',
      { method: 'POST', body: JSON.stringify({ email }) },
      () => {
        UIkit.notification('Email di conferma account inviata con successo', {
          status: 'success',
        });
      },
      () => {
        UIkit.notification(
          'Si è verificato un errore durante l\'invio dell\'email di conferma account',
          {
            status: 'danger',
          },
        );
      },
    );
  };

  render() {
    const { email } = this.state;
    return (
      <Page
        title='Email di conferma account'
        className='account-confirmation-email'
      >
        <Header title='Email di conferma account' />
        <WorkspaceContent>
          <input
            onChange={(e) => this.setState({ email: e.target.value })}
            type='email'
            value={email}
          />
          <Button
            onClick={this.onSubmit}
            disabled={validator.isEmpty(email) || !validator.isEmail(email)}
          >
            Invia email
          </Button>
        </WorkspaceContent>
      </Page>
    );
  }
}

export default connect(null, null)(AccountConfirmationEmailPage);
