import { connect } from 'react-redux';

import { logout } from 'store/authentication/actions';
import { getAPIToken, isBannerManager, isCensorManager } from 'helpers/authentication';
import { MainMenu, MainMenuBottomArea, MainMenuItem } from 'lib';

const PrimaryMenu = ({ logout }) => (
  <MainMenu>
    {!isBannerManager() && !isCensorManager() && <><MainMenuItem
      to='/merge-giocatori'
      tooltip='Merge giocatori'
      icon='code-fork'
    />
      <MainMenuItem to='/database' tooltip='Database' icon='database' />
      <MainMenuItem
        to='/marketplace'
        tooltip='Marketplace'
        icon='shopping-cart'
      />
      <MainMenuItem to='/partite' tooltip='Partite' icon='trophy' />
      <MainMenuItem
        to='/aggiornamento-dati'
        tooltip='Aggiornamento dati'
        icon='tasks'
      />
      <MainMenuItem to='/notifiche' tooltip='Notifiche' icon='bell' />
    </>}
    {!isCensorManager() && <>
      <MainMenuItem to='/utenti' tooltip='Utenti' icon='users' />
      <MainMenuItem to='/codici-membership' tooltip='Codici Membership' icon='barcode' />
      <MainMenuItem
        to='/custom-banner'
        tooltip='Custom Banner'
        icon='window-maximize'
      />
    </>}
    {!isBannerManager() && <MainMenuItem
      to='/segnalazioni'
      tooltip='Segnalazioni'
      icon='exclamation-triangle'
    />}
    {!isBannerManager() && !isCensorManager() && <MainMenuItem to='/adsense' tooltip='Adsense' icon='window-maximize' />}
    {!isCensorManager() && <MainMenuItem
      to='/gestione-banner'
      tooltip='Gestione Banner'
      icon='window-maximize'
    />}
    {!isBannerManager() && !isCensorManager() && <><MainMenuItem to='/cardinalita' tooltip='Cardinalità' icon='list-ol' />
      <MainMenuItem
        to='/richieste-giocatori'
        tooltip='Richieste giocatori'
        icon='vcard-o'
      />
      <MainMenuItem
        to='/email-conferma-account'
        tooltip='Email di conferma account'
        icon='vcard-o'
      />
    </>}

    <MainMenuBottomArea>
      <MainMenuItem tooltip='Esci' icon='power-off' onClick={() => logout()} />
    </MainMenuBottomArea>
  </MainMenu>
);

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout(getAPIToken())),
});

export default connect(null, mapDispatchToProps)(PrimaryMenu);
