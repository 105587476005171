import update from 'immutability-helper';

export const showLoader = (state) => update(state, { loading: { $set: true } });
export const hideLoader = (state) => update(state, { loading: { $set: false } });

export const clubManagersFetched = (state, action) => update(state, {
  totalPages: { $set: action.totalPages },
  clubManagers: {
    $set: action.clubManagers.map((clubManager) => ({
      ...clubManager, loaders: {
        approval: false,
      },
    }),
    ),
  },
});

export const clubManagersFetchFailed = (state, action) => update(state, {
  error: {
    $set:
      action.error && action.error.errors
        ? action.error.errors.join('\n')
        : true,
  },
});

export const clubManagerApproved = (state, action) => update(state, {
  clubManagers: {
    $set: state.clubManagers.map((clubManager) => clubManager.id === action.clubManagerProfileId
      ? { ...clubManager, status: 'approved', notes: action.notes }
      : clubManager,
    ),
  },
});

export const clubManagerApprovalFailed = (state, action) => clubManagersFetchFailed(state, action);

export const clubManagerRejected = (state, action) => update(state, {
  clubManagers: {
    $set: state.clubManagers.map((clubManager) => clubManager.id === action.clubManagerProfileId
      ? { ...clubManager, status: 'rejected', notes: action.notes }
      : clubManager,
    ),
  },
});

export const clubManagerRejectionFailed = (state, action) => clubManagersFetchFailed(state, action);
