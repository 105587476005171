import classNames from 'classnames';

import * as theme from '../../theme';

const baseStyle = {
  display: 'inline-block',
  borderRadius: theme.sizes.borderRadius,
  backgroundColor: theme.colors.white,
  borderWidth: '2px',
  borderColor: theme.colors.lightGrey,
  borderStyle: 'solid',
  lineHeight: 0,
  padding: '2px',
  cursor: 'pointer',
  ':hover': {
    borderColor: theme.colors.secondaryColor,
  },
};

const checkedBaseStyle = {
  borderColor: theme.colors.primaryColor,
};

const disabledBaseStyle = {
  cursor: 'not-allowed',
  borderColor: theme.colors.lightGrey,
  ':hover': {},
};

const baseFlagStyle = {
  display: 'inline-block',
  width: '12px',
  height: '12px',
  visibility: 'hidden',
};

const checkedFlagStyle = {
  display: 'inline-block',
  width: '12px',
  height: '12px',
  visibility: 'visible',
  backgroundColor: theme.colors.primaryColor,
  borderRadius: '1px',
};

const disabledFlagStyle = {
  borderColor: theme.colors.lightGrey,
  backgroundColor: theme.colors.lightGrey,
};

const CheckBox = ({ checked, disabled, onChange, className }) => {
  const isChecked = !!checked;
  const isDisabled = !!disabled;

  let style = baseStyle;
  if (isChecked) style = { ...style, ...checkedBaseStyle };
  if (isDisabled) style = { ...style, ...disabledBaseStyle };

  let flagStyle = baseFlagStyle;
  if (isChecked) flagStyle = { ...flagStyle, ...checkedFlagStyle };
  if (isDisabled) flagStyle = { ...flagStyle, ...disabledFlagStyle };

  return (
    <span
      onClick={() => (onChange ? onChange(!checked) : null)}
      className={classNames('check-box', className, checked ? 'checked' : null)}
      style={style}
    >
      <span className='flag' style={flagStyle} />
    </span>
  );
};

export default CheckBox;
