import API from 'api';
import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

export const runRestoreDump = (file) => () => {
  API.updateTasks.runRestoreDump(
    file,
    () => {
      UIkit.notification('Restore dump avviato con successo', {
        status: 'success',
      });
    },
    () => {
      UIkit.notification('Errore durante il restore del dump', {
        status: 'danger',
      });
    },
  );
};

export const fetchUpdateTasks = (page) => (dispatch) => {
  API.updateTasks.index(
    page,
    (response) => {
      dispatch(updateTasksFetched({ ...response }));
    },
    (error) => {
      dispatch(updateTasksFetchFailed(error));
    },
  );
};

export const runTask = (id, avoidSendMail) => () => {
  API.updateTasks.run(
    id,
    avoidSendMail,
    () => {
      UIkit.notification('Aggiornamento cominciato', {
        status: 'success',
      });
    },
    (response) => {
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    },
  );
};

export const setTaskPending = (id) => () => {
  API.updateTasks.setTaskPending(
    id,
    () => {
      UIkit.notification('Aggiornamento riattivato', {
        status: 'success',
      });
    },
    (response) => {
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    },
  );
};

export const saveUpdateTask = (update_task) => () => {
  UIkit.notification('Salvataggio in corso...');

  API.updateTasks.create(
    update_task,
    () => {
      UIkit.notification('Aggiornamento caricato con successo!', {
        status: 'success',
      });
    },
    (response) => {
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    },
  );
};

export const cancelCacheTask = () => () => {
  UIkit.notification('Cancellazione cache in corso...');

  API.updateTasks.cancelCache(
    () => {
      UIkit.notification('Task di cancellazione cache lanciato con successo!', {
        status: 'success',
      });
    },
    (response) => {
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    },
  );
};

export const restartServersTask = () => () => {
  UIkit.notification('Restart dei server in corso...');

  API.updateTasks.restartServers(
    () => {
      UIkit.notification('Restart servers lanciato con successo!', {
        status: 'success',
      });
    },
    (response) => {
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    },
  );
};

const updateTasksFetched = ({ total_pages, update_tasks, page }) => ({
  type: actionTypes.UPDATE_TASKS_FETCHED,
  totalPages: total_pages,
  update_tasks,
  page,
});
const updateTasksFetchFailed = (error) => ({
  type: actionTypes.UPDATE_TASKS_FETCH_FAILED,
  error,
});
