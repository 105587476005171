import moment from 'moment';

const COOKIE_KEY = `${import.meta.env.VITE_APP_ENV || 'development'
  }_tennistalker_current_user`;

const getCurrentUser = () => JSON.parse(localStorage.getItem(COOKIE_KEY));

export const isBannerManager = () => {
  const u = getCurrentUser();
  return u?.role === 'banner_manager';
};

export const isCensorManager = () => {
  const u = getCurrentUser();
  return u?.role === 'censor_manager';
};


const isExpired = (tokenExpiration) => moment(tokenExpiration).isSameOrBefore(new Date());

export const logoutFrontend = () => {
  unsetCurrentUser();
  window.location = '/accedi';
};

export const setCurrentUser = (user) => localStorage.setItem(COOKIE_KEY, JSON.stringify(user));

export const unsetCurrentUser = () => localStorage.removeItem(COOKIE_KEY);

export const currentUser = () => {
  const user = getCurrentUser();
  if (user) {
    if (isExpired(user.token_expiration)) {
      unsetCurrentUser();
      return null;
    }
    return user;
  }
  return user;
};

export const isLoggedIn = () => currentUser() && !isExpired(currentUser().token_expiration);

export const getAPIToken = () => (currentUser() ? currentUser().token : '');

export const isCurrentUser = (userId) => currentUser() ? currentUser().id === userId : false;
