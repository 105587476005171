import { Page } from 'ui/elements';
import { Button, Header, TextInput, WorkspaceContent } from 'lib';
import { useState } from 'react';
import { call } from 'api';
import uikit from 'uikit';

export const NotificationsPage = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [destinationUrl, setDestinationUrl] = useState('');
  const isValid = () => title !== '' && content !== '';
  const onClickFunction = () => {
    if (window.confirm('Sei sicuro di voler inviare questa notifica a tutti gli utenti?')) {
      call(
        'home/send_all_notifications',
        { method: 'POST', body: JSON.stringify({ title, content, destination_url: destinationUrl }) },
        () => {
          uikit.notification('Job di invio notifiche lanciato con successo', {
            status: 'success',
          });
        },
        (error) => {
          uikit.notification(
            JSON.stringify(error),
            {
              status: 'danger',
            },
          );
        },
      );
    }
  };
  return (
    <Page title='Notifiche' className='notifications'>
      <Header title='Invia notifiche a tutti gli utenti' />
      <WorkspaceContent>
        <div>
          <TextInput
            placeholder='title'
            value={title}
            onChange={(event) => setTitle(event.target.value)
            }
            className='uk-margin-small-bottom'
          />
          <TextInput
            placeholder='content'
            value={content}
            onChange={(event) => setContent(event.target.value)
            }
            className='uk-margin-small-bottom'
          />
          <TextInput
            placeholder='destination url'
            value={destinationUrl}
            onChange={(event) => setDestinationUrl(event.target.value)
            }
            className='uk-margin-small-bottom'
          />
          <div className='actions'>
            <Button disabled={!isValid()} onClick={onClickFunction}>
              Invia a tutti
            </Button>
          </div>
        </div>
      </WorkspaceContent>
    </Page>
  );
};

export default NotificationsPage;
