import classNames from 'classnames';

import * as theme from '../theme';

const baseStyle = {
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.colors.lightGrey,
};

export const Tr = ({ className, style, children, ...otherProps }) => (
  <tr className={classNames('tr', className)} style={{ ...baseStyle, ...style }} {...otherProps}>
    {children}
  </tr>
);
