import API from 'api';
import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

export const fetchNewCardinality = () => (dispatch) => {
  API.cardinality.fetchNewCardinality(
    (response) => {
      dispatch(setNewCardinality(response));
    },
    (error) => {
      dispatch(newCardinalityFailed(error));
    },
  );
};

export const save = (year, cardinality) => () => {
  API.cardinality.save(
    year,
    cardinality,
    () => {
      UIkit.notification('Operazione eseguita con successo.', {
        status: 'success',
      });
    },
    () => {
      UIkit.notification('Impossibile eseguire l\'operazione.', {
        status: 'danger',
      });
    },
  );
};

const setNewCardinality = (response) => ({
  type: actionTypes.FETCH_NEW_CARDINALITY,
  payload: response,
});

const newCardinalityFailed = (error) => ({
  type: actionTypes.NEW_CARDINALITY_FAILED,
  payload: error,
});
