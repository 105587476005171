import { call } from 'api';

export const login = ({ email, password }, successCallback, errorCallback) => call(
  'sessions/backoffice',
  { method: 'POST', body: JSON.stringify({ email, password }) },
  successCallback,
  errorCallback,
);

export const logout = (token, successCallback, errorCallback) => call(
  `sessions/${token}`,
  { method: 'DELETE' },
  successCallback,
  errorCallback,
);
