import { PureComponent } from 'react';

import { Button } from 'lib';
import DropZone from './DropZone';

class UploadTaskForm extends PureComponent {
  state = {
    file: null,
    filename: null,
    run_after_save: false,
  };

  onFileChange = (event) => {
    const reader = new FileReader();
    const filename = event?.target?.files?.[0]?.name;
    reader.addEventListener('load', () => {
      this.setState({ file: reader.result, filename: filename });
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  onFileDrop = (event) => {
    const reader = new FileReader();
    const filename = event?.dataTransfer?.files?.[0]?.name;
    reader.addEventListener('load', () => {
      this.setState({ file: reader.result, filename: filename });
    });
    reader.readAsDataURL(event.dataTransfer.files[0]);
  };

  render() {
    return (
      <form className='update-task-form'>
        <DropZone onFileDrop={this.onFileDrop}>
          <input type='file' onChange={this.onFileChange} className='uk-margin-small-bottom' />
        </DropZone>

        <div className='actions'>
          <Button
            className='uk-margin-right'
            disabled={!this.state.file}
            onClick={() => {
              this.props.saveUpdateTask(this.state);
              this.setState({ file: null, run_after_save: false });
            }}
          >
            Carica
          </Button>
          <Button
            className='uk-margin-right'
            disabled={!this.state.file}
            onClick={() => {
              this.props.saveUpdateTask({ ...this.state, run_after_save: true });
              this.setState({ file: null, run_after_save: false });
            }}
          >
            Carica ed esegui
          </Button>
          <Button
            className='uk-margin-right'
            onClick={(e) => {
              e.preventDefault();
              this.props.cancelCacheTask();
            }}
          >
            Cancella Cache
          </Button>

          <Button
            onClick={() => {
              this.props.restartServersTask();
            }}
          >
            Restart Server
          </Button>
        </div>
      </form>
    );
  }
}

export default UploadTaskForm;
