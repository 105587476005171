import classNames from 'classnames';

import * as theme from '../../theme';
import { Icon } from '../../elements/index.ts';

const RIGHT = 'right';

const isLeft = (iconSide) => iconSide !== RIGHT;
const isRight = (iconSide) => iconSide === RIGHT;

const activeStyle = { borderColor: theme.colors.primaryColor };

const baseStyle = {
  backgroundColor: theme.colors.white,
  paddingTop: theme.sizes.gutter.xs,
  paddingRight: theme.sizes.gutter.xs,
  paddingBottom: theme.sizes.gutter.xs,
  paddingLeft: theme.sizes.gutter.xs,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  borderRadius: theme.sizes.borderRadius,
  fontFamily: theme.font.family,
  color: theme.colors.black,
  minHeight: '44px',
  ':hover': {
    borderColor: theme.colors.secondaryColor,
  },
  ':focus': activeStyle,
  ':active': activeStyle,
};

const warningStyle = { borderColor: theme.colors.yellow };
const errorStyle = { borderColor: theme.colors.red };
const successStyle = { borderColor: theme.colors.green };

const withLeftIconStyle = { paddingLeft: '40px' };
const withRightIconStyle = { paddingRight: '40px' };

const iconStyle = { color: theme.colors.midLightGrey };

const TextInput = ({ type, warning, error, success, className, icon, iconSide, ...otherProps }) => {
  const renderedIcon = icon && <Icon name={icon} style={iconStyle} />;

  const additionalIconStyle = isLeft(iconSide) ? withLeftIconStyle : withRightIconStyle;
  let style = icon ? { ...baseStyle, ...additionalIconStyle } : baseStyle;
  if (icon) style = { ...baseStyle, ...additionalIconStyle };
  if (warning) style = { ...style, ...warningStyle };
  if (error) style = { ...style, ...errorStyle };
  if (success) style = { ...style, ...successStyle };

  return (
    <div className={classNames('text-input', 'uk-inline', 'uk-width-1-1', className)}>
      {icon && isLeft(iconSide) && <span className='uk-form-icon'>{renderedIcon}</span>}
      <input type={type} {...otherProps} className={classNames('uk-input', className)} style={style} />
      {icon && isRight(iconSide) && <span className='uk-form-icon uk-form-icon-flip'>{renderedIcon}</span>}
    </div>
  );
};

export default TextInput;
