import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { EditCustomBannerForm } from 'ui/components';
import { fetchBanner, updateBanner } from 'store/customBanners/actions';
import { useParams } from 'react-router-dom';

export const EditCustomBannerPage = (props) => {
  const { id } = useParams();
  useEffect(() => {
    props.fetch(id);
  }, []);

  return (
    <Page title='Modifica Custom Banner' className='edit-custom-banner'>
      <Header title='Modifica Custom Banner' />
      <WorkspaceContent>
        <EditCustomBannerForm onUpdate={props.update} banner={props.banner} />
      </WorkspaceContent>
    </Page>
  );
};

const mapStateToProps = (state) => ({ ...state.customBanners });
const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchBanner(id)),
  update: (id, data) => dispatch(updateBanner(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomBannerPage);
