import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { BannerForm, Banners, PaginationBar } from 'ui/components';
import { fetchBanners, deleteBanner } from 'store/banners/actions';
import { getAllCustomBanners } from 'store/customBanners/actions';
import { getAllAdsense } from 'store/adsense/actions';
import { isBannerManager } from 'helpers';

const restricted = isBannerManager();

class BannersManagementPage extends PureComponent {
  componentDidMount() {
    const { fetch, page, getAllCustomBanners, getAllAdsense } = this.props;
    getAllCustomBanners();
    getAllAdsense();
    fetch(page);
  }

  render() {
    const { page, totalPages, fetch, customBanners, adsenses } = this.props;
    return (
      <Page title='Banner' className='banners'>
        <Header title='Gestione Banner' />
        <WorkspaceContent>
          {!restricted && <BannerForm customBanners={customBanners} adsenses={adsenses} />}
          <Banners {...this.props} />
          <PaginationBar
            currentPage={page}
            totalPages={totalPages}
            onPreviousPageClick={() => fetch(page - 1)}
            onNextPageClick={() => fetch(page + 1)}
            onPageClick={(page) => fetch(page)}
          />
        </WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.banners,
  customBanners: state.customBanners.banners,
  adsenses: state.adsense.adsenses,
});
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchBanners(page)),
  deleteBanner: (id) => dispatch(deleteBanner(id)),
  getAllCustomBanners: () => dispatch(getAllCustomBanners()),
  getAllAdsense: () => dispatch(getAllAdsense()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BannersManagementPage);
