import { call } from 'api';

export const index = (successCallback, errorCallback) => call(
  'offer-categories/backoffice',
  { method: 'GET' },
  successCallback,
  errorCallback,
);

export const create = (category, successCallback, errorCallback) => call(
  'offer-categories',
  { method: 'POST', body: JSON.stringify(category) },
  successCallback,
  errorCallback,
);

export const update = (category, successCallback, errorCallback) => call(
  `offer-categories/${category.id}`,
  { method: 'PUT', body: JSON.stringify(category) },
  successCallback,
  errorCallback,
);

export const deleteOfferCategory = (
  categoryId,
  successCallback,
  errorCallback,
) => call(
  `offer-categories/${categoryId}`,
  { method: 'DELETE' },
  successCallback,
  errorCallback,
);
