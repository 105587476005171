import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import * as theme from '../theme';

const MainMenuItem = ({ icon, to, tooltip, onClick }) => {
  const tooltipProps = tooltip ? { title: tooltip, 'data-uk-tooltip': 'pos: right' } : null;
  const clickHandler = () => (onClick ? onClick() : null);

  return (
    <NavLink
      to={to || '#'}
      className='main-menu-item'
      style={({ isActive }) => ({
        display: 'block',
        padding: `${theme.sizes.gutter.xs} 0px`,
        backgroundColor: isActive ? theme.colors.secondaryColor : theme.colors.primaryColor,
        borderTopLeftRadius: theme.sizes.borderRadius,
        borderBottomLeftRadius: theme.sizes.borderRadius,
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '16pt',
        color: isActive ? theme.colors.white : theme.colors.halfWhite,
        borderRight: `${theme.sizes.gutter.xs} solid ${theme.colors.primaryColor}`,
        ':hover': {
          color: theme.colors.white,
        },
      })}
      onClick={clickHandler}
      {...tooltipProps}
    >
      <i className={classNames('fa', `fa-${icon}`)} />
    </NavLink>
  );
};

export default MainMenuItem;
