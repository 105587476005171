import API from 'api';
import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

export const getAllAdsense = () => (dispatch) => {
  API.adsense.all(
    (response) => {
      dispatch(allAdsenseFetched(response));
    },
    (error) => {
      dispatch(adsenseActionFailed(error));
    },
  );
};

export const save = (data, callback) => (dispatch) => {
  API.adsense.create(
    { adsense_slot: data },
    (response) => {
      UIkit.notification('Adsense inserito con successo', {
        status: 'success',
      });
      dispatch(adsenseCreated({ ...response.adsense_slot }));
      callback();
    },
    (error) => {
      dispatch(adsenseActionFailed(error));
    },
  );
};

export const fetchAdsenses = (page) => (dispatch) => {
  API.adsense.fetch(
    page,
    (response) => {
      dispatch(adsensesFetched(response));
    },
    (error) => {
      dispatch(adsenseActionFailed(error));
    },
  );
};

export const deleteAdsense = (id) => (dispatch) => {
  API.adsense.deleteAdsense(
    id,
    (response) => {
      UIkit.notification('Adsense eliminato con successo', {
        status: 'success',
      });
      dispatch(adsensesDeleted(response.adsense_slot));
    },
    (error) => {
      dispatch(adsenseActionFailed(error));
    },
  );
};

export const fetchAdsense = (id) => (dispatch) => {
  API.adsense.show(
    id,
    (response) => {
      dispatch(adsenseFetched(response));
    },
    (error) => {
      dispatch(adsenseActionFailed(error));
    },
  );
};

export const updateAdsense = (id, data) => (dispatch) => {
  API.adsense.update(
    id,
    data,
    () => {
      UIkit.notification('Adsense aggiornato con successo', {
        status: 'success',
      });
      window.location.href = '/adsense';
    },
    (error) => {
      dispatch(adsenseActionFailed(error));
    },
  );
};

const allAdsenseFetched = (response) => ({
  type: actionTypes.ADSENSE_ALL_FETCHED,
  payload: response,
});

const adsensesFetched = (response) => ({
  type: actionTypes.ADSENSES_FETCHED,
  payload: response,
});

const adsenseCreated = (adsense) => ({
  type: actionTypes.ADSENSE_CREATED,
  payload: adsense,
});

const adsensesDeleted = (adsense) => ({
  type: actionTypes.ADSENSE_DELETED,
  payload: adsense,
});

const adsenseFetched = (adsense) => ({
  type: actionTypes.ADSENSE_FETCHED,
  payload: adsense,
});

const adsenseActionFailed = (error) => ({
  type: actionTypes.ADSENSE_ACTION_FAILED,
  payload: error,
});
