import { useCallback, useEffect, useState } from 'react';

const DropZone = ({ children, onFileDrop }) => {
  const DragBox = ({ children }) => <div className='drag-box'>{children}</div>;

  const [isVisible, setIsVisible] = useState(false);

  const onDragEnter = useCallback((e) => {
    setIsVisible(true);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);
  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);
  const onDragLeave = useCallback((e) => {
    setIsVisible(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);
  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      //onst files = e.dataTransfer.files;
      onFileDrop(e);
      // Upload files
      setIsVisible(false);
      return false;
    },
    [onFileDrop],
  );

  useEffect(() => {
    window.addEventListener('mouseup', onDragLeave);
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('dragover', onDragOver);
    window.addEventListener('drop', onDrop);
    return () => {
      window.removeEventListener('mouseup', onDragLeave);
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('dragover', onDragOver);
      window.removeEventListener('drop', onDrop);
    };
  }, [onDragEnter, onDragLeave, onDragOver, onDrop]);

  return (
    <div>
      {children}
      <DragBox className='bg-secondary' isVisible={isVisible} onDragLeave={onDragLeave}>
        Drop files to Upload
      </DragBox>
    </div>
  );
};

export default DropZone;
