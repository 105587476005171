import { call } from 'api';
import { Button } from 'lib';
import { useEffect, useState } from 'react';
import uikit from 'uikit';

export const EditMembershipCodesForm = (props) => {
  const [id, setId] = useState();
  const [duration, setDuration] = useState('');
  const [consumed, setConsumed] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (props.membershipCode) {
      setId(props.membershipCode.id);
      setConsumed(props.membershipCode.consumed ?? false);
      setDuration(props.membershipCode.duration ?? '');
      setEmail(props.membershipCode.email ?? '');
    }
  }, [props.membershipCode]);

  const isValid = () => duration !== '';

  const handleSave = (event) => {
    event.preventDefault();
    if (props.onUpdate) {
      props.onUpdate(id, {
        id,
        duration,
        consumed,
        email,
      });
    } else if (props.onCreate) {
      props.onCreate({
        duration,
        consumed,
        email,
      });
    } else {
      uikit.notification('Impossibile creare codice membership.', {
        status: 'danger',
      });
    }
  };

  const handleSendMailAgain = (event) => {
    event.preventDefault();
    call(
      'premium_tokens/membership_email',
      { method: 'POST', body: JSON.stringify({ email: props.membershipCode.email, id: props.membershipCode.id }) },
      () => {
        uikit.notification('Email reinviata inviata con successo', {
          status: 'success',
        });
      },
      (error) => {
        uikit.notification(JSON.stringify(error), {
          status: 'danger',
        });
      },
    );
  };

  return (
    <form>
      <p>
        <strong>Duration:</strong>
        <input type='text' value={duration} onChange={(event) => setDuration(event.target.value)} />
      </p>
      <p>
        <strong>Email:</strong>
        <input type='email' value={email} onChange={(event) => setEmail(event.target.value)} />
      </p>
      <p>
        <strong>Consumed:</strong>
        <input type='checkbox' checked={consumed} onChange={() => setConsumed(!consumed)} />
      </p>
      <Button onClick={handleSave} disabled={!isValid()}>
        Salva
      </Button>
      {props.membershipCode && (
        <Button className='uk-margin-left' onClick={handleSendMailAgain}>
          Reinvia mail
        </Button>
      )}
    </form>
  );
};
