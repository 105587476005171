import 'whatwg-fetch';

import { getAPIToken, logoutFrontend } from 'helpers/authentication';

const handleCatch = (error, errorCallback) => {
  errorCallback(error);
};
const handleData = (response) => {
  if (response.status === 401) logoutFrontend();
  if (!response || !response.ok || response.status >= 400) throw response;
  else return response.json();
};
const handleResponse = (response, successCallback) => {
  if (!response || response.success === false) throw response;
  else if (successCallback) successCallback(response);
  else return response;
};

export const baseUrlByEnvironment = () => {
  switch (import.meta.env.VITE_APP_ENV) {
    case 'production':
      return import.meta.env.VITE_APP_API_URL_PROD;
    case 'staging':
      return import.meta.env.VITE_APP_API_URL_STAGING;
    default:
      return import.meta.env.VITE_APP_API_URL;
  }
};

export const call = (url, data, successCallback, errorCallback) => {
  const headers = new Headers({
    'X-Api-Key': getAPIToken(),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });
  const requestDetails = { ...data, headers };

  return fetch(`${baseUrlByEnvironment()}${url}`, requestDetails)
    .catch((error) => handleCatch(error, errorCallback))
    .then((response) => handleData(response))
    .then((response) => handleResponse(response, successCallback))
    .catch((error) => errorCallback(error));
};
