import { call } from 'api';

export const runRestoreDump = (file, successCallback, errorCallback) => call(
  'update-tasks/run-restore-dump',
  { method: 'POST', body: JSON.stringify({ dump_filename: file }) },
  successCallback,
  errorCallback,
);

export const index = (page = 1, successCallback, errorCallback) => call(
  `update-tasks?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback,
);

export const create = (update_task, successCallback, errorCallback) => call(
  'update-tasks',
  { method: 'POST', body: JSON.stringify(update_task) },
  successCallback,
  errorCallback,
);

export const run = (id, avoidSendMail, successCallback, errorCallback) => call(
  `update-tasks/${id}/run`,
  { method: 'PUT', body: JSON.stringify({ avoid_send_mail: avoidSendMail }) },
  successCallback,
  errorCallback,
);

export const setTaskPending = (id, successCallback, errorCallback) => call(
  `update-tasks/${id}/set_pending`,
  { method: 'PUT' },
  successCallback,
  errorCallback,
);

export const cancelCache = (successCallback, errorCallback) => call(
  'update-tasks/cache_refresh',
  { method: 'POST' },
  successCallback,
  errorCallback,
);

export const restartServers = (successCallback, errorCallback) => call(
  'update-tasks/restart_servers',
  { method: 'POST' },
  successCallback,
  errorCallback,
);
